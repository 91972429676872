import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { FaArrowLeft, FaCopy } from 'react-icons/fa';
import axios from 'axios';
import QRCode from 'qrcode.react';
import { useUser } from '../contexts/UserContext';

const API_BASE_URL = 'https://onering.simping.click/apedex/';

// Helper function to calculate relative time
function getRelativeTime(timestamp) {
  const now = new Date();
  const transactionDate = new Date(timestamp);
  const diffInSeconds = Math.floor((now - transactionDate) / 1000);

  if (diffInSeconds < 60) {
    return 'Just now';
  } else if (diffInSeconds < 3600) {
    const minutes = Math.floor(diffInSeconds / 60);
    return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
  } else if (diffInSeconds < 86400) {
    const hours = Math.floor(diffInSeconds / 3600);
    return `${hours} hour${hours > 1 ? 's' : ''} ago`;
  } else {
    const days = Math.floor(diffInSeconds / 86400);
    return `${days} day${days > 1 ? 's' : ''} ago`;
  }
}

function DollarDetail() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { user } = useUser();
  const [transaction, setTransaction] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    const fetchTransactionDetails = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`${API_BASE_URL}pays/${id}?token=${user.token}`);
        setTransaction(response.data);
        setError(null);
      } catch (err) {
        console.error('Error fetching transaction details:', err);
        setError('Failed to fetch transaction details. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    fetchTransactionDetails();
  }, [id, user.token]);

  const getStatusText = (status) => {
    switch (status) {
      case 'LIP':
        return 'Pending';
      case 'completed':
        return 'Completed';
      default:
        return status.charAt(0).toUpperCase() + status.slice(1);
    }
  };

  const copyPaymentRequest = () => {
    navigator.clipboard.writeText(transaction.payment_request);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  if (loading) return <div className="flex items-center justify-center h-screen text-2xl text-yellow-600 animate-pulse">Loading... 🚀</div>;
  if (error) return <div className="flex items-center justify-center h-screen text-2xl text-red-600">Error: {error} 😢</div>;
  if (!transaction) return <div className="flex items-center justify-center h-screen text-2xl text-red-600">Transaction not found 🔍</div>;

  return (
    <div className="p-4 bg-gradient-to-b from-yellow-100 to-orange-200 min-h-screen">
      <div className="max-w-2xl mx-auto">
        <div className="flex items-center mb-6">
          <button
            onClick={() => navigate('/dollar')}
            className="mr-4 bg-brown-800 text-yellow-400 p-2 rounded-lg hover:bg-brown-900 transition-colors"
          >
            <FaArrowLeft />
          </button>
          <h1 className="text-3xl font-bold text-brown-800">🐵 Dollar</h1>
        </div>

        <div className="bg-gradient-to-r from-yellow-300 to-orange-400 p-8 rounded-xl shadow-lg">
          <div className="space-y-4">
            <InfoRow label="Dollar" value={`$${parseFloat(transaction.dollar).toFixed(2)}`} />
            <InfoRow label="Sats" value={transaction.sats.toLocaleString()} />
            <InfoRow label="Status" value={getStatusText(transaction.status)} />
            <InfoRow
              label="Timestamp"
              value={getRelativeTime(transaction.created_at)}
              tooltip={new Date(transaction.created_at).toLocaleString()}
            />
            <InfoRow label="Type" value={transaction.deposit ? 'Deposit' : 'Withdrawal'} />
            <InfoRow label="Network" value={transaction.lightning ? 'Lightning' : 'On-chain'} />

            <div className="space-y-1">
              <div className="font-bold text-brown-800">Payment Hash:</div>
              <div className="text-brown-600 break-all bg-white p-2 rounded-lg">
                {transaction.payment_hash}
              </div>
            </div>

            <div className="space-y-1">
              <div className="flex items-center">
                <span className="font-bold text-brown-800 mr-2">Payment Request:</span>
                <button
                  onClick={copyPaymentRequest}
                  className="bg-brown-800 text-yellow-400 p-2 rounded-lg hover:bg-brown-900 transition-colors"
                >
                  <FaCopy />
                </button>
                {copied && <span className="text-green-600 text-sm ml-2">Copied!</span>}
              </div>
              <div className="text-brown-600 break-all bg-white p-2 rounded-lg">
                {transaction.payment_request}
              </div>
            </div>
          </div>

          {transaction.status === 'LIP' && transaction.payment_request && (
            <div className="mt-8">
              <h2 className="text-xl font-bold mb-4 text-brown-800">Payment QR Code</h2>
              <div className="flex justify-center bg-white p-4 rounded-lg">
                <QRCode value={transaction.payment_request} size={256} />
              </div>
              <p className="mt-4 text-center text-brown-600">
                Scan this QR code to complete the payment
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

function InfoRow({ label, value, tooltip }) {
  return (
    <div className="flex justify-between items-center">
      <span className="font-bold text-brown-800">{label}:</span>
      <span className="text-brown-600" title={tooltip}>{value}</span>
    </div>
  );
}

export default DollarDetail;
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { FaInfoCircle, FaExchangeAlt, FaComments, FaGlobe, FaTelegram, FaTwitter, FaExchangeAlt as FaSwap } from 'react-icons/fa';
import { useUser } from '../contexts/UserContext';
import { mintTokens, burnTokens } from '../utils/tokenEconomics';

function Coin() {
    const { id } = useParams();
    const [coin, setCoin] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [activeTab, setActiveTab] = useState('About');
    const [orderType, setOrderType] = useState('mint');
    const [orderAmount, setOrderAmount] = useState('');
    const [chatMessage, setChatMessage] = useState('');
    const { user } = useUser();

    useEffect(() => {
        const fetchCoin = async () => {
            try {
                setLoading(true);
                const coinResponse = await axios.get(`https://onering.simping.click/apedex/coins/${id}?token=${user.token}`);
                setCoin(coinResponse.data);
                setError(null);
            } catch (err) {
                console.error('Error fetching data:', err);
                setError(`Failed to fetch data: ${err.message}`);
            } finally {
                setLoading(false);
            }
        };

        fetchCoin();
    }, [id, user.token]);

    if (loading) return <div className="flex items-center justify-center h-screen text-2xl text-yellow-600 animate-pulse">Loading... 🚀</div>;
    if (error) return <div className="flex items-center justify-center h-screen text-2xl text-red-600">Error: {error} 😢</div>;
    if (!coin) return <div className="flex items-center justify-center h-screen text-2xl text-red-600">Coin not found 🔍</div>;

    const renderTabContent = () => {
        switch (activeTab) {
            case 'About':
                return (
                    <div className="space-y-4">
                        <div className="bg-white p-4 rounded-lg shadow-md">
                            <img src={coin.banner_url} alt={`${coin.name} banner`} className="w-full h-40 object-cover rounded-t-lg mb-4" />
                            <p className="mb-4">{coin.description}</p>
                            <div className="flex space-x-2 mb-4">
                                <a href={coin.website} target="_blank" rel="noopener noreferrer" className="bg-blue-500 text-xs text-white px-2 py-1 rounded-full hover:bg-blue-600 transition-colors">
                                    <FaGlobe className="inline mr-2" />Website
                                </a>
                                <a href={coin.telegram} target="_blank" rel="noopener noreferrer" className="bg-blue-500 text-xs text-white px-2 py-1 rounded-full hover:bg-blue-600 transition-colors">
                                    <FaTelegram className="inline mr-2" />Telegram
                                </a>
                                <a href={coin.twitter} target="_blank" rel="noopener noreferrer" className="bg-blue-500 text-xs text-white px-2 py-1 rounded-full hover:bg-blue-600 transition-colors">
                                    <FaTwitter className="inline mr-2" />Twitter
                                </a>
                            </div>
                        </div>
                        <div className="bg-white p-4 rounded-lg shadow-md">
                            <h3 className="text-xl font-bold mb-2">Top Holders</h3>
                            <ul className="space-y-2">
                                {coin.holders.map((holder, index) => (
                                    <li key={index} className="bg-gradient-to-r from-yellow-100 to-orange-200 p-4 rounded-lg shadow-md">
                                        <div className="flex justify-between items-center">
                                            <span className="font-medium">{holder.holder}</span>
                                            <span>{Number(holder.token).toLocaleString()} tokens</span>
                                            <span className="text-green-600">{holder.percentage}%</span>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                );
            case 'Swap':
                return (
                    <div className="bg-white p-4 rounded-lg shadow-md">
                        <h3 className="text-xl font-bold mb-2">Recent Swaps</h3>
                        {coin.swaps && coin.swaps.length > 0 ? (
                            <ul className="space-y-2">
                                {coin.swaps.map((swap, index) => (
                                    <li key={index} className="bg-gradient-to-r from-yellow-100 to-orange-200 p-4 rounded-lg shadow-md">
                                        <div className="flex justify-between items-center">
                                            <span>{new Date(swap.timestamp).toLocaleString()}</span>
                                            <span>{swap.user}</span>
                                            <span>{swap.token_amount} tokens</span>
                                            <span>${swap.dollar_amount}</span>
                                            <span className={swap.type === 'mint' ? 'text-green-600' : 'text-red-600'}>
                                                {swap.type === 'mint' ? '🔨 Mint' : '🔥 Burn'}
                                            </span>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <div className="text-center py-8">
                                <FaExchangeAlt className="mx-auto text-5xl text-yellow-500 mb-4" />
                                <h3 className="text-xl font-bold text-brown-800 mb-2">No Swaps Yet</h3>
                                <p className="text-brown-600 mb-4">Be the first to swap this coin!</p>
                                <p className="text-brown-600">Use the swap panel below to mint or burn tokens.</p>
                            </div>
                        )}
                    </div>
                );
            case 'Chat':
                return (
                    <div className="bg-white p-4 rounded-lg shadow-md">
                        <h3 className="text-xl font-bold mb-2">Chat</h3>
                        {coin.chats && coin.chats.length > 0 ? (
                            <div className="h-64 bg-gradient-to-r from-yellow-100 to-orange-200 rounded-lg p-2 mb-4 overflow-y-auto">
                                {coin.chats.map((chat, index) => (
                                    <div key={index} className="mb-2">
                                        <span className="font-bold">{chat.user}: </span>
                                        <span>{chat.message}</span>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <div className="text-center py-8 bg-gradient-to-r from-yellow-100 to-orange-200 rounded-lg mb-4">
                                <FaComments className="mx-auto text-5xl text-yellow-500 mb-4" />
                                <h3 className="text-xl font-bold text-brown-800 mb-2">No Chats Yet</h3>
                                <p className="text-brown-600 mb-4">Be the first to start a conversation about this token!</p>
                                <p className="text-brown-600">Use the chat box below to share your thoughts.</p>
                            </div>
                        )}
                        <div className="relative">
                            <input
                                type="text"
                                value={chatMessage}
                                onChange={(e) => setChatMessage(e.target.value)}
                                placeholder="Type your message..."
                                className="w-full p-2 pr-16 rounded-lg focus:ring-2 focus:ring-yellow-500 bg-gradient-to-r from-yellow-100 to-orange-200"
                            />
                            <button className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-blue-500 text-white px-4 py-1 rounded-lg hover:bg-blue-600 transition-colors">
                                Send
                            </button>
                        </div>
                    </div>
                );
            default:
                return null;
        }
    };

    // Calculate token economics
    const calculateTokenEconomics = () => {
        const currentSupply = coin.total_supply;
        if (orderType === 'mint') {
            const [, tokensMinted] = mintTokens(Number(orderAmount), currentSupply);
            return `${tokensMinted.toFixed(4)} tokens`;
        } else {
            const [, dollarsReceived] = burnTokens(Number(orderAmount), currentSupply);
            return `$${dollarsReceived.toFixed(2)}`;
        }
    };

    return (
        <div className="flex flex-col h-screen bg-gradient-to-b from-yellow-50 to-orange-100">
            {/* Top segment */}
            <div className="flex-none bg-gradient-to-r from-yellow-300 to-orange-400 shadow-md p-4">
                <div className="flex items-center justify-between">
                    <div className="flex items-center">
                        <img src={coin.icon_url} alt={coin.symbol} className="w-16 h-16 rounded-full mr-4" />
                        <div>
                            <h1 className="text-2xl font-bold text-brown-800">{coin.name}</h1>
                            <p className="text-lg text-brown-600">${coin.symbol}</p>
                        </div>
                    </div>
                    <div className="text-right">
                        <p className="text-xl font-bold text-brown-800">${coin.marketcap.toLocaleString()}</p>
                        <p className="text-lg text-brown-600">{coin.creator_username}</p>
                    </div>
                </div>
            </div>

            {/* Content segment */}
            <div className="flex-grow overflow-y-auto">
                <div className="p-4">
                    <div className="flex space-x-2 mb-4 overflow-x-auto pb-2">
                        {['About', 'Swap', 'Chat'].map((tab) => (
                            <button
                                key={tab}
                                className={`px-4 py-2 rounded-full transition-colors duration-200 flex-shrink-0 ${activeTab === tab
                                        ? 'bg-brown-800 text-yellow-400 shadow-inner'
                                        : 'bg-yellow-400 text-brown-800 hover:bg-yellow-500'
                                    }`}
                                onClick={() => setActiveTab(tab)}
                            >
                                {tab === 'About' && <FaInfoCircle className="inline mr-1" />}
                                {tab === 'Swap' && <FaExchangeAlt className="inline mr-1" />}
                                {tab === 'Chat' && <FaComments className="inline mr-1" />}
                                {tab}
                            </button>
                        ))}
                    </div>
                    {renderTabContent()}
                </div>
            </div>

            {/* Order segment */}
            <div className="flex-none bg-gradient-to-r from-yellow-300 to-orange-400 p-4 shadow-md">
                <p className="text-brown-800 font-bold text-center mb-2">
                    Balance: {orderType === 'mint'
                        ? `$${(user.mdollar / 1000000000).toFixed(2)}`
                        : `${coin.balance.token.toLocaleString()} tokens`}
                </p>
                <div className="flex space-x-2">
                    <button
                        onClick={() => setOrderType(orderType === 'mint' ? 'burn' : 'mint')}
                        className="bg-brown-800 text-yellow-400 p-2 rounded-lg hover:bg-brown-900 transition-colors"
                    >
                        <FaSwap className="text-xl" />
                    </button>
                    <input
                        type="number"
                        value={orderAmount}
                        onChange={(e) => setOrderAmount(e.target.value)}
                        placeholder={orderType === 'mint' ? 'Enter dollar amount' : 'Enter token amount'}
                        className="flex-grow p-2 rounded-lg focus:ring-2 focus:ring-yellow-500 bg-white"
                    />
                    <button className={`px-6 py-2 rounded-lg text-white font-bold transition-colors duration-200 ${orderType === 'mint' ? 'bg-green-500 hover:bg-green-600' : 'bg-red-500 hover:bg-red-600'
                        }`}>
                        {orderType === 'mint' ? 'Mint' : 'Burn'}
                    </button>
                </div>
                <p className="mt-2 text-center text-brown-800">
                    Estimated to receive: {calculateTokenEconomics()}
                </p>
            </div>
        </div>
    );
}

export default Coin;
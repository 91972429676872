import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useUser } from '../contexts/UserContext';

const Modal = ({ isOpen, onClose, children }) => {
    if (!isOpen) return null;
    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-gradient-to-r from-yellow-200 to-orange-300 p-6 rounded-lg shadow-xl">
                {children}
                <button onClick={onClose} className="mt-4 bg-brown-800 text-yellow-400 px-4 py-2 rounded-full hover:bg-brown-900 transition duration-300">Close</button>
            </div>
        </div>
    );
};

const LoadingSpinner = () => (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
        <div className="animate-spin rounded-full h-32 w-32 border-t-4 border-b-4 border-yellow-400"></div>
    </div>
);

function CoinLaunch() {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        name: '',
        symbol: '',
        description: '',
        website: '',
        twitter: '',
        telegram: '',
    });
    const [icon, setIcon] = useState(null);
    const [banner, setBanner] = useState(null);
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [launchedCoinId, setLaunchedCoinId] = useState(null);
    const { user } = useUser();

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleFileChange = (e) => {
        const { name, files } = e.target;
        if (name === 'icon') {
            setIcon(files[0]);
        } else if (name === 'banner') {
            setBanner(files[0]);
        }
    };

    const validateForm = () => {
        const newErrors = {};
        if (formData.name.length < 3 || formData.name.length > 32) {
            newErrors.name = 'Name must be between 3 and 32 characters';
        }
        if (formData.symbol.length < 1 || formData.symbol.length > 32) {
            newErrors.symbol = 'Symbol must be between 1 and 32 characters';
        }
        if (formData.description.length < 100 || formData.description.length > 1000) {
            newErrors.description = 'Description must be between 100 and 1000 characters';
        }
        if (!icon) {
            newErrors.icon = 'Icon is required';
        }
        if (!banner) {
            newErrors.banner = 'Banner is required';
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) {
            return;
        }

        setIsLoading(true);
        const formDataToSend = new FormData();

        if (window.Telegram && window.Telegram.WebApp) {
            formDataToSend.append('init_data', window.Telegram.WebApp.initData);
        } else {
            console.error('Telegram WebApp is not available');
            setErrors({ general: 'Telegram WebApp is not available. Please make sure you are using the Telegram app.' });
            setShowErrorModal(true);
            setIsLoading(false);
            return;
        }

        Object.keys(formData).forEach(key => {
            formDataToSend.append(key, formData[key]);
        });
        formDataToSend.append('icon', icon);
        formDataToSend.append('banner', banner);

        try {
            var url = `https://onering.simping.click/apedex/coins/?token=${user.token}`
            const response = await axios.post(url, formDataToSend, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            console.log('Coin launched successfully:', response.data);
            setLaunchedCoinId(response.data.id);
            setShowSuccessModal(true);
        } catch (error) {
            console.error('Error launching coin:', error.response ? error.response.data : error.message);
            setErrors(error.response ? error.response.data : { general: 'An error occurred while launching the coin' });
            setShowErrorModal(true);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="p-4 bg-gradient-to-b from-yellow-100 to-orange-200 min-h-screen">
            <h1 className="text-3xl font-bold mb-6 text-brown-800 text-center">Launch Your Coin</h1>
            <form onSubmit={handleSubmit} className="space-y-6 max-w-2xl mx-auto bg-gradient-to-r from-yellow-300 to-orange-400 p-8 rounded-xl shadow-lg">
                <div>
                    <label htmlFor="name" className="block mb-1 font-medium text-brown-800">Coin Name</label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        className="w-full p-3 border-2 border-yellow-500 rounded-lg focus:ring-2 focus:ring-orange-500 bg-yellow-100"
                        required
                        minLength="3"
                        maxLength="32"
                        placeholder="Enter your coin's name"
                    />
                    {errors.name && <p className="text-red-600 text-sm mt-1 animate-pulse">{errors.name}</p>}
                </div>

                <div>
                    <label htmlFor="symbol" className="block mb-1 font-medium text-brown-800">Symbol</label>
                    <input
                        type="text"
                        id="symbol"
                        name="symbol"
                        value={formData.symbol}
                        onChange={handleInputChange}
                        className="w-full p-3 border-2 border-yellow-500 rounded-lg focus:ring-2 focus:ring-orange-500 bg-yellow-100"
                        required
                        minLength="1"
                        maxLength="32"
                        placeholder="Enter your coin's symbol"
                    />
                    {errors.symbol && <p className="text-red-600 text-sm mt-1 animate-pulse">{errors.symbol}</p>}
                </div>

                <div>
                    <label htmlFor="description" className="block mb-1 font-medium text-brown-800">Description</label>
                    <textarea
                        id="description"
                        name="description"
                        value={formData.description}
                        onChange={handleInputChange}
                        className="w-full p-3 border-2 border-yellow-500 rounded-lg focus:ring-2 focus:ring-orange-500 bg-yellow-100"
                        required
                        minLength="100"
                        maxLength="1000"
                        rows="4"
                        placeholder="Describe your coin (100-1000 characters)"
                    ></textarea>
                    {errors.description && <p className="text-red-600 text-sm mt-1 animate-pulse">{errors.description}</p>}
                </div>

                <div className="flex space-x-4">
                    <div className="flex-1">
                        <label htmlFor="icon" className="block mb-1 font-medium text-brown-800">Icon 🖼️</label>
                        <input
                            type="file"
                            id="icon"
                            name="icon"
                            onChange={handleFileChange}
                            className="w-full p-2 border-2 border-yellow-500 rounded-lg focus:ring-2 focus:ring-orange-500 bg-yellow-100 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-orange-50 file:text-orange-700 hover:file:bg-orange-100"
                            accept="image/png,image/jpeg,image/gif"
                            required
                        />
                        {errors.icon && <p className="text-red-600 text-sm mt-1 animate-pulse">{errors.icon}</p>}
                    </div>
                    <div className="flex-1">
                        <label htmlFor="banner" className="block mb-1 font-medium text-brown-800">Banner 🏞️</label>
                        <input
                            type="file"
                            id="banner"
                            name="banner"
                            onChange={handleFileChange}
                            className="w-full p-2 border-2 border-yellow-500 rounded-lg focus:ring-2 focus:ring-orange-500 bg-yellow-100 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-orange-50 file:text-orange-700 hover:file:bg-orange-100"
                            accept="image/png,image/jpeg,image/gif"
                            required
                        />
                        {errors.banner && <p className="text-red-600 text-sm mt-1 animate-pulse">{errors.banner}</p>}
                    </div>
                </div>

                <div>
                    <label htmlFor="website" className="block mb-1 font-medium text-brown-800">Website URL 🌐</label>
                    <input
                        type="url"
                        id="website"
                        name="website"
                        value={formData.website}
                        onChange={handleInputChange}
                        className="w-full p-3 border-2 border-yellow-500 rounded-lg focus:ring-2 focus:ring-orange-500 bg-yellow-100"
                        required
                        placeholder="https://yourcoin.com"
                    />
                    {errors.website && <p className="text-red-600 text-sm mt-1 animate-pulse">{errors.website}</p>}
                </div>

                <div>
                    <label htmlFor="twitter" className="block mb-1 font-medium text-brown-800">Twitter URL 🐦</label>
                    <input
                        type="url"
                        id="twitter"
                        name="twitter"
                        value={formData.twitter}
                        onChange={handleInputChange}
                        className="w-full p-3 border-2 border-yellow-500 rounded-lg focus:ring-2 focus:ring-orange-500 bg-yellow-100"
                        required
                        placeholder="https://twitter.com/yourcoin"
                    />
                    {errors.twitter && <p className="text-red-600 text-sm mt-1 animate-pulse">{errors.twitter}</p>}
                </div>

                <div>
                    <label htmlFor="telegram" className="block mb-1 font-medium text-brown-800">Telegram URL 📢</label>
                    <input
                        type="url"
                        id="telegram"
                        name="telegram"
                        value={formData.telegram}
                        onChange={handleInputChange}
                        className="w-full p-3 border-2 border-yellow-500 rounded-lg focus:ring-2 focus:ring-orange-500 bg-yellow-100"
                        required
                        placeholder="https://t.me/yourcoin"
                    />
                    {errors.telegram && <p className="text-red-600 text-sm mt-1 animate-pulse">{errors.telegram}</p>}
                </div>

                <button
                    type="submit"
                    className="w-full bg-gradient-to-r from-green-400 to-blue-500 text-white p-3 rounded-lg hover:from-green-500 hover:to-blue-600 transition-all duration-300 transform hover:scale-105 font-bold text-lg"
                    disabled={isLoading}
                >
                    {isLoading ? '🚀 Launching...' : '🚀 Launch Coin'}
                </button>
            </form>

            {isLoading && <LoadingSpinner />}

            <Modal isOpen={showSuccessModal} onClose={() => setShowSuccessModal(false)}>
                <h2 className="text-2xl font-bold mb-4 text-brown-800">🎉 Coin Launched Successfully! 🎉</h2>
                <p className="text-lg text-brown-700 mb-4">Your coin has been created with ID: {launchedCoinId}</p>
                <button
                    onClick={() => navigate(`/coin/${launchedCoinId}`)}
                    className="bg-gradient-to-r from-green-400 to-blue-500 text-white px-6 py-3 rounded-full hover:from-green-500 hover:to-blue-600 transition-all duration-300 transform hover:scale-105 font-bold"
                >
                    🚀 Go to Coin Page
                </button>
            </Modal>

            <Modal isOpen={showErrorModal} onClose={() => setShowErrorModal(false)}>
                <h2 className="text-2xl font-bold mb-4 text-red-600">❌ Error Launching Coin</h2>
                <p className="text-lg text-brown-700 mb-4">{errors.general || "An unexpected error occurred. Please try again."}</p>
            </Modal>
        </div>
    );
}

export default CoinLaunch;
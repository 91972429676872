import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useUser } from '../contexts/UserContext';

const API_BASE_URL = 'https://onering.simping.click/apedex/coins/';

function Dashboard() {
  const [coins, setCoins] = useState([]);
  const [moonshot, setMoonshot] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filter, setFilter] = useState('marketcap');
  const { user } = useUser();

  const fetchCoins = useCallback(async (currentFilter) => {
    try {
      setLoading(true);
      const url = `${API_BASE_URL}?category=${currentFilter}&token=${user.token}`;
      console.log('Fetching coins from:', url);

      const response = await axios.get(url);
      const fetchedCoins = response.data.coins;
      const fetchedMoonshot = response.data.moonshot;
      console.log('Fetched coins:', fetchedCoins);
      setMoonshot(fetchedMoonshot);

      if (fetchedCoins.length > 0) {
        setCoins(fetchedCoins);
      } else {
        setCoins([]);
        setMoonshot(null);
      }
      setError(null);
    } catch (err) {
      console.error('Error fetching coins:', err);
      setError('Failed to fetch coins. Please try again later.');
    } finally {
      setLoading(false);
    }
  }, [user.token]);

  useEffect(() => {
    fetchCoins(filter);
    const intervalId = setInterval(() => fetchCoins(filter), 30000);
    return () => clearInterval(intervalId);
  }, [fetchCoins, filter]);

  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
    fetchCoins(newFilter);
  };

  if (loading && coins.length === 0) return <div className="text-center mt-12 text-2xl text-yellow-700 animate-bounce">Loading... 🚀</div>;
  if (error) return <div className="text-center mt-12 text-2xl text-red-600 animate-pulse">{error} 😢</div>;

  return (
    <div className="p-4 bg-gradient-to-b from-yellow-100 to-orange-200 min-h-screen">
      {moonshot && (
        <Link to={`/coin/${moonshot.id}`} className="block bg-gradient-to-r from-yellow-300 to-orange-400 rounded-xl overflow-hidden shadow-lg mb-6 transform hover:scale-105 transition duration-300">
          <img src={moonshot.banner_url} alt={moonshot.name} className="w-full h-40 object-cover" />
          <div className="p-4">
            <h3 className="text-2xl font-bold text-brown-800 mb-2">{moonshot.name} <span className="text-orange-600">({moonshot.symbol})</span></h3>
            <p className="text-sm text-brown-700 mb-3 line-clamp-2">{moonshot.description}</p>
            <div className="flex justify-between items-center">
              <p className="text-lg font-bold text-brown-900">MC: ${moonshot.marketcap.toLocaleString()}</p>
              <span className="text-sm bg-yellow-500 text-white px-2 py-1 rounded-full animate-pulse">🚀 Moonshot</span>
            </div>
          </div>
        </Link>
      )}

      <Link to="/coin-launch" className="block w-full py-3 mb-6 bg-gradient-to-r from-green-400 to-blue-500 text-white text-center text-xl font-bold rounded-lg shadow-md hover:shadow-lg transform hover:scale-105 transition duration-300">
        🚀 Launch Your Coin 🌙
      </Link>

      <div className="flex overflow-x-auto pb-2 mb-4">
        {['marketcap', 'new', 'hot', 'controversial'].map((category) => (
          <button
            key={category}
            onClick={() => handleFilterChange(category)}
            className={`mr-2 px-6 py-3 rounded-full text-base font-semibold transition-all duration-300 ${filter === category
                ? 'bg-brown-800 text-yellow-400 shadow-inner transform scale-105'
                : 'bg-yellow-400 text-brown-800 hover:bg-yellow-500 hover:transform hover:scale-105'
              }`}
          >
            {category.charAt(0).toUpperCase() + category.slice(1)}
          </button>
        ))}
      </div>

      <ul className="space-y-4">
        {coins.map((coin) => (
          <li key={coin.id} className="bg-gradient-to-r from-yellow-200 to-orange-300 rounded-lg overflow-hidden shadow-md hover:shadow-lg transition duration-300">
            <Link to={`/coin/${coin.id}`} className="flex items-center p-3 hover:bg-yellow-400 transition duration-300">
              <img src={coin.icon_url} alt={coin.name} className="w-16 h-16 rounded-full mr-4 bg-white p-1 border-2 border-yellow-500" />
              <div className="flex-grow min-w-0">
                <div className="flex items-baseline">
                  <h2 className="text-xl font-bold text-brown-800 truncate mr-2">{coin.name}</h2>
                  <span className="text-sm font-medium text-orange-600">({coin.symbol})</span>
                </div>
                <p className="text-xs text-gray-600 truncate">Created by: {coin.creator_username || 'Unknown'}</p>
              </div>
              <div className="text-right">
                <p className="text-lg font-bold text-brown-800">MC: ${coin.marketcap.toLocaleString()}</p>
                <span className="text-xs bg-yellow-500 text-white px-2 py-1 rounded-full">Rank #{coins.indexOf(coin) + 1}</span>
              </div>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Dashboard;
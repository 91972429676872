import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaCopy, FaUserFriends, FaCoins, FaShare } from 'react-icons/fa';
import { useUser } from '../contexts/UserContext';

const API_BASE_URL = 'https://onering.simping.click/apedex/';

function Friends() {
  const { user } = useUser();
  const [friendsData, setFriendsData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    const fetchFriendsData = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`${API_BASE_URL}referrals/?token=${user.token}`);
        setFriendsData(response.data);
        setError(null);
      } catch (err) {
        console.error('Error fetching friends data:', err);
        setError('Failed to fetch friends data. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    fetchFriendsData();
  }, [user.token]);

  const handleCopyLink = () => {
    navigator.clipboard.writeText(friendsData.personal.share_link);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  if (loading) return <div className="flex items-center justify-center h-screen text-2xl text-yellow-600 animate-pulse">Loading... 🚀</div>;
  if (error) return <div className="flex items-center justify-center h-screen text-2xl text-red-600">Error: {error} 😢</div>;
  if (!friendsData) return <div className="flex items-center justify-center h-screen text-2xl text-red-600">No data found 🔍</div>;

  return (
    <div className="p-4 bg-gradient-to-b from-yellow-100 to-orange-200 min-h-screen">
      <div className="max-w-2xl mx-auto">
        <div className="bg-gradient-to-r from-yellow-300 to-orange-400 p-6 rounded-xl shadow-lg mb-6">
          <h1 className="text-2xl font-bold text-brown-800 mb-4">🐒 Friends</h1>
          <div className="mb-4">
            <p className="text-brown-800 font-semibold mb-2">Referral Link:</p>
            <div className="flex items-center bg-white p-2 rounded-lg">
              <input
                type="text"
                value={friendsData.personal.share_link}
                readOnly
                className="flex-grow bg-transparent outline-none"
              />
              <button
                onClick={handleCopyLink}
                className="ml-2 bg-brown-800 text-yellow-400 p-2 rounded-lg hover:bg-brown-900 transition-colors"
              >
                <FaCopy />
              </button>
            </div>
            {copied && <p className="text-green-600 text-sm mt-1">Copied!</p>}
          </div>
          <div className="flex justify-between items-center">
            <div className="flex items-center">
              <FaUserFriends className="text-brown-800 mr-2" />
              <p className="text-brown-800">Friends: {friendsData.friends}</p>
            </div>
            <div className="flex items-center">
              <FaCoins className="text-brown-800 mr-2" />
              <p className="text-brown-800">🍌: ${user.banana}</p>
            </div>
          </div>
        </div>

        <h2 className="text-xl font-bold text-brown-800 mb-4">Referral Revenues</h2>
        {friendsData.referrals.length > 0 ? (
          <div className="space-y-4">
            {friendsData.referrals.map((referral, index) => (
              <div key={index} className="bg-white p-4 rounded-lg shadow-md">
                <div className="flex justify-between items-center mb-2">
                  <p className="font-semibold text-brown-800">{referral.coin.name} ({referral.coin.symbol})</p>
                  <p className="text-brown-600">@{referral.clicker.telegram_username}</p>
                </div>
                <div className="flex justify-between items-center">
                  <p className="text-brown-800">Dollar: ${(referral.mdollar / 1000000000).toFixed(2)}</p>
                  <p className="text-brown-800">Banana: {referral.banana}</p>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="bg-white p-6 rounded-xl shadow-md text-center">
            <FaShare className="text-5xl text-yellow-500 mx-auto mb-4" />
            <h3 className="text-xl font-bold text-brown-800 mb-2">No Referrals Yet</h3>
            <p className="text-brown-600 mb-4">Share your referral link to start earning!</p>
            <p className="text-brown-800 font-semibold">Here's how:</p>
            <ol className="text-left list-decimal list-inside mt-2 space-y-2">
              <li>Copy your referral link above</li>
              <li>Share it with friends and on social media</li>
              <li>Earn rewards when people use your link</li>
            </ol>
          </div>
        )}
      </div>
    </div>
  );
}

export default Friends;
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaHourglassHalf, FaCheckCircle } from 'react-icons/fa';
import { useUser } from '../contexts/UserContext';

const API_BASE_URL = 'https://onering.simping.click/apedex/';

function Rewards() {
  const { user } = useUser();
  const [rewardsData, setRewardsData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchRewardsData = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`${API_BASE_URL}rewards/?token=${user.token}`);
        setRewardsData(response.data);
        setError(null);
      } catch (err) {
        console.error('Error fetching rewards data:', err);
        setError('Failed to fetch rewards data. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    fetchRewardsData();
  }, [user.token]);

  if (loading) return <div className="flex items-center justify-center h-screen text-2xl text-yellow-600 animate-pulse">Loading... 🚀</div>;
  if (error) return <div className="flex items-center justify-center h-screen text-2xl text-red-600">Error: {error} 😢</div>;
  if (!rewardsData) return <div className="flex items-center justify-center h-screen text-2xl text-red-600">No data found 🔍</div>;

  return (
    <div className="p-4 bg-gradient-to-b from-yellow-100 to-orange-200 min-h-screen">
      <div className="max-w-2xl mx-auto">
        <div className="mb-12">
          <h2 className="text-xl font-semibold text-brown-800 mb-4">Rewards Summary</h2>
          <div className="bg-gradient-to-r from-yellow-300 to-orange-400 bg-opacity-50 backdrop-filter backdrop-blur-lg rounded-xl shadow-xl overflow-hidden border border-yellow-500 p-6">
            <div className="grid grid-cols-2 gap-4">
              <div>
                <p className="text-sm text-brown-600 mb-1">Banana</p>
                <p className="text-lg font-medium text-brown-800">{rewardsData.banana_amount} 🍌</p>
              </div>
              <div>
                <p className="text-sm text-brown-600 mb-1">Rewards Completed</p>
                <p className="text-lg font-medium text-green-600">{rewardsData.rewards_completed}</p>
              </div>
              <div>
                <p className="text-sm text-brown-600 mb-1">Rewards Pending</p>
                <p className="text-lg font-medium text-blue-600">{rewardsData.rewards_pending}</p>
              </div>
              <div>
                <p className="text-sm text-brown-600 mb-1">Level</p>
                <p className="text-lg font-medium text-purple-600">{rewardsData.level}</p>
              </div>
            </div>
          </div>
        </div>

        <h2 className="text-xl font-bold text-brown-800 mb-4">Pending Rewards</h2>
        {rewardsData.pending_rewards && rewardsData.pending_rewards.length > 0 ? (
          <div className="space-y-4 mb-6">
            {rewardsData.pending_rewards.map((reward, index) => (
              <div key={index} className="bg-white p-4 rounded-lg shadow-md flex justify-between items-center">
                <p className="font-semibold text-brown-800">{reward.name}</p>
                <p className="text-yellow-500 font-bold">{reward.amount} 🍌</p>
              </div>
            ))}
          </div>
        ) : (
          <div className="bg-white p-6 rounded-xl shadow-md text-center mb-6">
            <FaHourglassHalf className="text-5xl text-yellow-500 mx-auto mb-4" />
            <h3 className="text-xl font-bold text-brown-800 mb-2">No Pending Rewards</h3>
            <p className="text-brown-600">Check back later for new reward opportunities!</p>
          </div>
        )}

        <h2 className="text-xl font-bold text-brown-800 mb-4">Completed Rewards</h2>
        {rewardsData.completed_rewards && rewardsData.completed_rewards.length > 0 ? (
          <div className="space-y-4">
            {rewardsData.completed_rewards.map((reward, index) => (
              <div key={index} className="bg-white p-4 rounded-lg shadow-md">
                <div className="flex justify-between items-center mb-2">
                  <p className="font-semibold text-brown-800">{reward.name}</p>
                  <p className="text-yellow-500 font-bold">{reward.amount} 🍌</p>
                </div>
                <p className="text-sm text-brown-600">Completed: {new Date(reward.timestamp).toLocaleString()}</p>
              </div>
            ))}
          </div>
        ) : (
          <div className="bg-white p-6 rounded-xl shadow-md text-center">
            <FaCheckCircle className="text-5xl text-green-500 mx-auto mb-4" />
            <h3 className="text-xl font-bold text-brown-800 mb-2">No Completed Rewards Yet</h3>
            <p className="text-brown-600 mb-2">Complete pending tasks to earn rewards!</p>
            <p className="text-brown-600">Keep an eye on the Pending Rewards section for opportunities.</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default Rewards;
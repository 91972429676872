import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { FaArrowLeft, FaQuestionCircle } from 'react-icons/fa';
import axios from 'axios';
import { useUser } from '../contexts/UserContext';

const API_BASE_URL = 'https://onering.simping.click/apedex/';

function Holding() {
    const { id } = useParams();
    const navigate = useNavigate();
    const { user } = useUser();
    const [holdingData, setHoldingData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchHoldingData = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`${API_BASE_URL}holdings/${id}?token=${user.token}`);
                setHoldingData(response.data);
                setError(null);
            } catch (err) {
                console.error('Error fetching holding data:', err);
                setError('Failed to fetch holding data. Please try again.');
            } finally {
                setLoading(false);
            }
        };

        fetchHoldingData();
    }, [id, user.token]);

    if (loading) return <div className="flex items-center justify-center h-screen text-2xl text-yellow-600 animate-pulse">Loading... 🚀</div>;
    if (error) return <div className="flex items-center justify-center h-screen text-2xl text-red-600">Error: {error} 😢</div>;
    if (!holdingData || !holdingData.holding) return <div className="flex items-center justify-center h-screen text-2xl text-red-600">Holding data not found 🔍</div>;

    const { holding, swaps } = holdingData;
    const marketValue = holding.market_value || 0;
    const costBasis = holding.cost_basis || 0;
    const unrealizedProfitLoss = marketValue - costBasis;
    const profitLossPercentage = costBasis !== 0 ? (unrealizedProfitLoss / costBasis) * 100 : 0;

    const renderValue = (value, defaultValue = 'N/A') => {
        return value != null && value !== '' ? value : defaultValue;
    };

    return (
        <div className="p-4 bg-gradient-to-b from-yellow-100 to-orange-200 min-h-screen">
            <div className="max-w-2xl mx-auto">
                <div className="flex items-center mb-6">
                    <button
                        onClick={() => navigate('/portfolio')}
                        className="mr-4 bg-brown-800 text-yellow-400 p-2 rounded-lg hover:bg-brown-900 transition-colors"
                    >
                        <FaArrowLeft />
                    </button>
                    <h1 className="text-3xl font-bold text-brown-800">
                        {renderValue(holding.coin?.name)} ({renderValue(holding.coin?.symbol)})
                    </h1>
                </div>

                <div className="bg-gradient-to-r from-yellow-300 to-orange-400 p-6 rounded-xl shadow-lg mb-6">
                    <h2 className="text-xl font-bold text-brown-800 mb-4">Holding Summary</h2>
                    <div className="grid grid-cols-2 gap-4">
                        <div>
                            <p className="text-sm text-brown-600">Quantity</p>
                            <p className="text-lg font-bold text-brown-800">{renderValue(holding.quantity?.toLocaleString(), '0')}</p>
                        </div>
                        <div>
                            <p className="text-sm text-brown-600">Market Value</p>
                            <p className="text-lg font-bold text-brown-800">${renderValue(marketValue.toFixed(2), '0.00')}</p>
                        </div>
                        <div>
                            <p className="text-sm text-brown-600">Cost Basis</p>
                            <p className="text-lg font-bold text-brown-800">${renderValue(costBasis.toFixed(2), '0.00')}</p>
                        </div>
                        <div>
                            <p className="text-sm text-brown-600">Unrealized P/L</p>
                            <p className={`text-lg font-bold ${unrealizedProfitLoss >= 0 ? 'text-green-600' : 'text-red-600'}`}>
                                ${renderValue(unrealizedProfitLoss.toFixed(2), '0.00')} ({renderValue(profitLossPercentage.toFixed(2), '0.00')}%)
                            </p>
                        </div>
                    </div>
                </div>

                <h2 className="text-2xl font-bold text-brown-800 mb-4">Swap Transactions</h2>
                {swaps && swaps.length > 0 ? (
                    <div className="space-y-4">
                        {swaps.map((swap, index) => (
                            <div key={index} className="bg-white p-4 rounded-lg shadow-md">
                                <div className="flex justify-between items-center mb-2">
                                    <p className="font-semibold text-brown-800">
                                        {renderValue(swap.type) === 'buy' ? 'Buy' : 'Sell'} {renderValue(Math.abs(swap.quantity || 0).toLocaleString(), '0')} {renderValue(holding.coin?.symbol)}
                                    </p>
                                    <p className={`font-bold ${renderValue(swap.type) === 'buy' ? 'text-green-600' : 'text-red-600'}`}>
                                        ${renderValue(Math.abs(swap.amount || 0).toFixed(2), '0.00')}
                                    </p>
                                </div>
                                <p className="text-sm text-brown-600">Date: {renderValue(swap.timestamp ? new Date(swap.timestamp).toLocaleString() : null, 'Date not available')}</p>
                                <p className="text-sm text-brown-600">
                                    Price: ${renderValue(((Math.abs(swap.amount || 0) / Math.abs(swap.quantity || 1)) || 0).toFixed(6), '0.000000')} per {renderValue(holding.coin?.symbol)}
                                </p>
                            </div>
                        ))}
                    </div>
                ) : (
                    <div className="bg-white p-4 rounded-lg shadow-md text-center">
                        <FaQuestionCircle className="text-4xl text-yellow-500 mx-auto mb-2" />
                        <p className="text-brown-600">No swap transactions available</p>
                    </div>
                )}
            </div>
        </div>
    );
}

export default Holding;
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useUser } from '../contexts/UserContext';
import axios from 'axios';

function Intro() {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { user, updateUser } = useUser();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const initializeApp = async () => {
            try {
                setLoading(true);
                let initData = '';
                let webApp = null;

                if (window.Telegram && window.Telegram.WebApp) {
                    webApp = window.Telegram.WebApp;
                    webApp.ready();
                    initData = webApp.initData;
                }

                const response = await axios.post('https://onering.simping.click/apedex/token/', { init_data: initData });
                const { token, user_id, level,  telegram_id, telegram_username, telegram_first_name, telegram_last_name, mdollar, banana, has_start_param, start_param } = response.data;

                console.log('Data received: ', response.data)

                updateUser({ token, user_id, level,  telegram_id, telegram_username, telegram_first_name, telegram_last_name, mdollar, banana });

                if (webApp) {
                    webApp.expand();
                }


                if (has_start_param && token) {
                    const responseTwo = await axios.get(`https://onering.simping.click/apedex/links/${start_param}?token=${user.token}`);
                    const { goto } = responseTwo.data;

                    if (goto) {
                        console.log('Goto: ', goto)
                        navigate(`${goto}`);
                    } else {
                        setError('Failed to initialize the app. Please try again.');
                        setLoading(false);
                    }

                } else {
                    navigate('/dashboard');
                }
            } catch (error) {
                console.error('Error initializing app:', error);
                setError('Failed to initialize the app. Please try again.');
            } finally {
                setLoading(false);
            }
        };

        initializeApp();
    }, [updateUser, navigate, location, user.token]);

    if (loading) {
        return (
            <div className="flex items-center justify-center min-h-screen bg-gray-100">
                <div className="text-center">
                    <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900 mx-auto"></div>
                    <p className="mt-4 text-xl font-semibold text-gray-700">Initializing...</p>
                </div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="flex items-center justify-center min-h-screen bg-gray-100">
                <div className="text-center">
                    <p className="text-xl font-semibold text-red-600">{error}</p>
                    <button
                        onClick={() => window.location.reload()}
                        className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition duration-300"
                    >
                        Retry
                    </button>
                </div>
            </div>
        );
    }

    return null; // The component will navigate away before rendering
}

export default Intro;
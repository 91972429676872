import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
// import { useUser } from '../contexts/UserContext';

const Topbar = () => {
  // const { user } = useUser();
  const navigate = useNavigate();

  return (
    <div className="topbar">
      <Link to="/" className="topbar-title">
        🐵
      </Link>
      <div className="topbar-buttons">
      <button 
          onClick={() => navigate('/dollar')}
          className="topbar-button"
        >
          💸
        </button>     
        <button 
          onClick={() => navigate('/friends')}
          className="topbar-button"
        >
          🍌
        </button>             
        <button 
          onClick={() => navigate('/portfolio')}
          className="topbar-button"
        >
          💼 
        </button>
        <button
          onClick={() => navigate('/profile')}
          className="topbar-button"
          aria-label="Profile"
        >
          🧑🏻‍💻
        </button>
      </div>
    </div>
  );
};

export default Topbar;
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { UserProvider } from './contexts/UserContext';
import { TonConnectUIProvider } from '@tonconnect/ui-react';

import './App.css'; // Assuming this is where Tailwind is imported
import Topbar from './components/Topbar';

import Intro from './views/Intro';
import Dashboard from './views/Dashboard';
import Coin from './views/Coin';
import Rewards from './views/Rewards';
import Portfolio from './views/Portfolio';
import Profile from './views/Profile';
import Dollar from './views/Dollar';
import DollarDetail from './views/DollarDetail';
import Friends from './views/Friends';
import CoinLaunch from './views/CoinLaunch';
import Holding from './views/Holding';

const TONCONNECT_MANIFEST_URL = 'https://ape-dex.pages.dev/tonconnect-manifest.json';

function App() {
  return (
    <TonConnectUIProvider manifestUrl={TONCONNECT_MANIFEST_URL}>
      <UserProvider>
        <Router>
          <div className="App">
            <Topbar />
            <div className="main-content">
              <Routes>
                <Route path="/" element={<Intro />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/coin/:id" element={<Coin />} />
                <Route path="/portfolio" element={<Portfolio />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/rewards" element={<Rewards />} />
                <Route path="/dollar" element={<Dollar />} />
                <Route path="/dollar/:id" element={<DollarDetail />} />
                <Route path="/holding/:id" element={<Holding />} />
                <Route path="/friends" element={<Friends />} />
                <Route path="/coin-launch" element={<CoinLaunch />} />
                <Route path="*" element={<div className="p-4">Page not found</div>} />
              </Routes>
            </div>
          </div>
        </Router>
      </UserProvider>
    </TonConnectUIProvider>
  );
}

export default App;
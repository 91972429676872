// src/utils/tokenEconomics.js

const MAX_SUPPLY = 1_000_000_000; // 1 billion tokens
const MAX_MINT_INPUT = 1_000_000_000; // Maximum 1 billion microdollars ($1000) input for minting
const MIN_MINT_INPUT = 1_000_000; // Minimum 1 million microdollars ($1) input for minting

function priceFunction(x) {
  return 4.05721 * Math.pow(x / 10_000_000, 0.6989);
}

function poolMoney(supply) {
  // Simple numerical integration (trapezoidal rule)
  const steps = 1000;
  const dx = supply / steps;
  let sum = 0;
  for (let i = 0; i < steps; i++) {
    const x1 = i * dx;
    const x2 = (i + 1) * dx;
    sum += (priceFunction(x1) + priceFunction(x2)) / 2 * dx;
  }
  return sum;
}

function marketCap(supply) {
  return supply * priceFunction(supply);
}

function convertToMicrodollars(amount, unit) {
  switch (unit) {
    case 'microdollar': return amount;
    case 'cent': return amount * 10000;
    case 'dollar': return amount * 1000000;
    default: throw new Error("Invalid unit. Use 'microdollar', 'cent', or 'dollar'.");
  }
}

function convertFromMicrodollars(amount, unit) {
  switch (unit) {
    case 'microdollar': return amount;
    case 'cent': return Math.round(amount / 10000 * 100) / 100;
    case 'dollar': return Math.round(amount / 1000000 * 100) / 100;
    default: throw new Error("Invalid unit. Use 'microdollar', 'cent', or 'dollar'.");
  }
}

function mintTokens(amount, currentSupply, inputUnit = 'dollar', outputUnit = 'dollar') {
  let microdollars = convertToMicrodollars(amount, inputUnit);
  microdollars = Math.max(Math.min(Math.floor(microdollars), MAX_MINT_INPUT), MIN_MINT_INPUT);

  function tokensToMicrodollar(tokens) {
    const newSupply = Math.min(currentSupply + tokens, MAX_SUPPLY);
    return poolMoney(newSupply) - poolMoney(currentSupply) - microdollars;
  }

  const maxPossibleTokens = MAX_SUPPLY - currentSupply;
  
  // Binary search to find the number of tokens
  let low = 0;
  let high = maxPossibleTokens;
  let tokensMinted;

  while (low <= high) {
    const mid = Math.floor((low + high) / 2);
    const value = tokensToMicrodollar(mid);
    
    if (Math.abs(value) < 1) {
      tokensMinted = mid;
      break;
    } else if (value < 0) {
      low = mid + 1;
    } else {
      high = mid - 1;
    }
  }

  tokensMinted = Math.floor(tokensMinted);
  const newSupply = Math.min(currentSupply + tokensMinted, MAX_SUPPLY);
  const newPoolMoney = poolMoney(newSupply);
  const newMarketCap = marketCap(newSupply);

  return [
    newSupply,
    tokensMinted,
    Math.floor(newPoolMoney),
    convertFromMicrodollars(newMarketCap, 'dollar')
  ];
}

function burnTokens(tokens, currentSupply, outputUnit = 'dollar') {
  tokens = Math.floor(tokens);
  const newSupply = Math.max(0, currentSupply - tokens);

  const microdollarsReceived = poolMoney(currentSupply) - poolMoney(newSupply);
  const newPoolMoney = poolMoney(newSupply);
  const newMarketCap = marketCap(newSupply);

  return [
    newSupply,
    convertFromMicrodollars(microdollarsReceived, outputUnit),
    Math.floor(newPoolMoney),
    convertFromMicrodollars(newMarketCap, 'dollar')
  ];
}

export {
  MAX_SUPPLY,
  MAX_MINT_INPUT,
  MIN_MINT_INPUT,
  priceFunction,
  poolMoney,
  marketCap,
  convertToMicrodollars,
  convertFromMicrodollars,
  mintTokens,
  burnTokens
};
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useUser } from '../contexts/UserContext';

const API_BASE_URL = 'https://onering.simping.click/apedex/';

function Portfolio() {
  const [portfolio, setPortfolio] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { user } = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPortfolio = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${API_BASE_URL}holdings?token=${user.token}`);
        setPortfolio(response.data);
        setError(null);
      } catch (err) {
        console.error('Error fetching holdings:', err);
        setError('Failed to fetch holdings. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    fetchPortfolio();
  }, [user.token]);

  const totalValue = portfolio.reduce((sum, holding) => sum + holding.market_value, 0);
  const totalCost = portfolio.reduce((sum, holding) => sum + holding.cost_basis, 0);
  const unrealizedProfitLoss = totalValue - totalCost;

  const handleCoinClick = (coinId) => {
    navigate(`/holding/${coinId}`);
  };

  if (loading) return <div className="text-center p-4">Loading...</div>;
  if (error) return <div className="text-center p-4 text-red-600">{error}</div>;

  return (
    <div className="p-4 bg-gradient-to-b from-yellow-100 to-orange-200 min-h-screen">
      <div className="max-w-2xl mx-auto">
        <div className="bg-gradient-to-r from-yellow-300 to-orange-400 p-6 rounded-xl shadow-lg mb-6">
          <h1 className="text-2xl font-bold text-brown-800 mb-4">Portfolio Summary</h1>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <p className="text-sm text-brown-600">Total Value</p>
              <p className="text-lg font-bold text-brown-800">${totalValue.toFixed(2)}</p>
            </div>
            <div>
              <p className="text-sm text-brown-600">🍌 Available</p>
              <p className="text-lg font-bold text-brown-800">{user.banana}</p>
            </div>
            <div>
              <p className="text-sm text-brown-600">Current Holdings</p>
              <p className="text-lg font-bold text-brown-800">{portfolio.length}</p>
            </div>
            <div>
              <p className="text-sm text-brown-600">Unrealized P/L</p>
              <p className={`text-lg font-bold ${unrealizedProfitLoss >= 0 ? 'text-green-600' : 'text-red-600'}`}>
                ${unrealizedProfitLoss.toFixed(2)}
              </p>
            </div>
          </div>
        </div>

        <div className="space-y-4">
          {portfolio.map((holding) => (
            <div
              key={holding.coin.id}
              className="bg-white p-4 rounded-lg shadow-md cursor-pointer hover:bg-yellow-50 transition-colors duration-200"
              onClick={() => handleCoinClick(holding.coin.id)}
            >
              <div className="flex justify-between items-center">
                <div>
                  <h2 className="text-xl font-bold text-brown-800">{holding.coin.name}</h2>
                  <p className="text-sm text-brown-600">{holding.coin.symbol}</p>
                </div>
                <div className="text-right">
                  <p className="text-lg font-medium text-brown-800">{holding.token.toLocaleString()} tokens</p>
                  <p className="text-sm text-brown-600">${holding.market_value.toFixed(2)}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Portfolio;
import React, { createContext, useState, useContext, useEffect } from 'react';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState({
    token: null,
    user_id: null,
    level: 0,
    telegram_id: null,
    telegram_username: null,
    telegram_first_name: null,
    telegram_last_name: null,
    mdollar: 0,
    banana: 0,
  });
  const [initData, setInitData] = useState(null);

  useEffect(() => {
    if (window.Telegram && window.Telegram.WebApp) {
      const telegramInitData = window.Telegram.WebApp.initData;
      setInitData(telegramInitData);
      window.Telegram.WebApp.expand();
    }
  }, []);

  const updateUser = (data) => {
    setUser(prevUser => ({ ...prevUser, ...data }));
  };

  return (
    <UserContext.Provider value={{ user, updateUser, initData }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};
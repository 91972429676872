import React, { useState, useEffect, useCallback } from 'react';
import { useUser } from '../contexts/UserContext';
import { useNavigate } from 'react-router-dom';
import { FaExchangeAlt, FaCoins } from 'react-icons/fa';
import axios from 'axios';

const API_BASE_URL = 'https://onering.simping.click/apedex/';

function getRelativeTime(timestamp) {
    const now = new Date();
    const transactionDate = new Date(timestamp);
    const diffInSeconds = Math.floor((now - transactionDate) / 1000);

    if (diffInSeconds < 60) {
        return 'Just now';
    } else if (diffInSeconds < 3600) {
        const minutes = Math.floor(diffInSeconds / 60);
        return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
    } else if (diffInSeconds < 86400) {
        const hours = Math.floor(diffInSeconds / 3600);
        return `${hours} hour${hours > 1 ? 's' : ''} ago`;
    } else {
        const days = Math.floor(diffInSeconds / 86400);
        return `${days} day${days > 1 ? 's' : ''} ago`;
    }
}

function Dollar() {
    const { user } = useUser();
    const navigate = useNavigate();
    const [transactions, setTransactions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [btcPrices, setBtcPrices] = useState(null);
    const [amount, setAmount] = useState('');
    const [estimatedSats, setEstimatedSats] = useState(0);
    const [orderType, setOrderType] = useState('buy');

    const fetchBtcPrices = useCallback(async () => {
        try {
            const url = `${API_BASE_URL}btc-price?token=${user.token}`;
            const response = await axios.get(url);
            setBtcPrices(response.data);
        } catch (error) {
            console.error('Error fetching BTC prices:', error);
            setError('Failed to fetch BTC prices');
        }
    }, [user.token]);

    const fetchTransactions = useCallback(async () => {
        setLoading(true);
        setError(null);
        try {
            const url = `${API_BASE_URL}pays/?token=${user.token}`;
            const response = await axios.get(url);
            if (Array.isArray(response.data)) {
                setTransactions(response.data);
            } else {
                console.error('Unexpected response format:', response.data);
                setTransactions([]);
                setError('Received invalid data format for transactions');
            }
        } catch (error) {
            console.error('Error fetching transactions:', error);
            setTransactions([]);
            setError('Failed to fetch transactions');
        } finally {
            setLoading(false);
        }
    }, [user.token]);

    useEffect(() => {
        fetchBtcPrices();
        fetchTransactions();
    }, [fetchBtcPrices, fetchTransactions]);

    useEffect(() => {
        if (btcPrices && amount) {
            let sats;
            if (orderType === 'buy') {
                sats = (parseFloat(amount) / btcPrices.sell_dollar) * 100000000;
            } else { // sell
                sats = (parseFloat(amount) / btcPrices.buy_dollar) * 100000000;
            }
            setEstimatedSats(Math.round(sats));
        } else {
            setEstimatedSats(0);
        }
    }, [amount, btcPrices, orderType]);

    const handleAmountChange = (e) => {
        const value = e.target.value;
        if (value === '' || (parseFloat(value) >= 1.00 && parseFloat(value) <= 999.99)) {
            // Ensure only two decimal places
            const formattedValue = value.includes('.') ? value.slice(0, value.indexOf('.') + 3) : value;
            setAmount(formattedValue);
        }
    };

    const handleTransaction = async () => {
        const parsedAmount = parseFloat(amount);
        if (!parsedAmount || parsedAmount < 1.00 || parsedAmount > 999.99) {
            alert('Please enter a valid amount between 1.00 and 999.99');
            return;
        }

        try {
            const url = `${API_BASE_URL}flows/?token=${user.token}`;
            const response = await axios.post(url, {
                network: 'lightning',
                task: orderType === 'buy' ? 'deposit' : 'withdraw',
                dollar: parsedAmount
            });

            const transactionId = response.data.id;

            setAmount('');
            setEstimatedSats(0);
            navigate(`/dollar/${transactionId}`);
        } catch (error) {
            console.error(`Error ${orderType}ing dollar:`, error);
            alert(`Failed to ${orderType} dollar. Please try again.`);
        }
    };

    const getStatusText = (status) => {
        switch (status) {
            case 'LIP':
                return 'Pending';
            case 'completed':
                return 'Completed';
            default:
                return status.charAt(0).toUpperCase() + status.slice(1);
        }
    };

    const renderTransactions = () => {
        if (loading) {
            return <p className="text-center text-brown-600">Loading transactions...</p>;
        }

        if (error) {
            return <p className="text-center text-red-600">Error: {error}</p>;
        }

        if (transactions.length === 0) {
            return (
                <div className="text-center py-8">
                    <FaCoins className="mx-auto text-5xl text-yellow-500 mb-4" />
                    <h3 className="text-xl font-bold text-brown-800 mb-2">No Transactions Yet</h3>
                    <p className="text-brown-600 mb-4">Start your journey by buying some dollars!</p>
                </div>
            );
        }

        return (
            <ul className="space-y-2">
                {transactions.map((tx) => (
                    <li
                        key={tx.id}
                        className="bg-white p-3 rounded-lg shadow flex justify-between items-center cursor-pointer hover:bg-yellow-100 transition-colors duration-200"
                        onClick={() => navigate(`/dollar/${tx.id}`)}
                    >
                        <div>
                            <p className="font-bold text-brown-800">
                                ${parseFloat(tx.dollar).toFixed(2)} ({tx.sats} sats)
                            </p>
                            <p className="text-sm text-brown-600">
                                {getRelativeTime(tx.created_at)}
                            </p>
                        </div>
                        <div className="text-right">
                            <p className={`text-sm ${tx.status === 'completed' ? 'text-green-600' : 'text-yellow-600'}`}>
                                {getStatusText(tx.status)}
                            </p>
                            <p className="text-sm text-brown-600">
                                {tx.deposit ? 'Deposit' : 'Withdrawal'}
                            </p>
                        </div>
                    </li>
                ))}
            </ul>
        );
    };

    return (
        <div className="p-4 bg-gradient-to-b from-yellow-100 to-orange-200 min-h-screen">
            <h1 className="text-3xl font-bold mb-6 text-brown-800 text-center">🐵 Dollar</h1>

            <div className="max-w-2xl mx-auto bg-gradient-to-r from-yellow-300 to-orange-400 p-8 rounded-xl shadow-lg">

                <div className="mb-6">
                    <div className="flex justify-between items-center mb-2">
                        <p className="text-brown-800 font-bold">
                            Balance: ${(user.mdollar / 1000000).toFixed(2)}
                        </p>
                        <button
                            onClick={() => setOrderType(orderType === 'buy' ? 'sell' : 'buy')}
                            className="bg-brown-800 text-yellow-400 p-2 rounded-lg hover:bg-brown-900 transition-colors"
                        >
                            <FaExchangeAlt className="text-xl" />
                        </button>
                    </div>
                    <input
                        type="number"
                        value={amount}
                        onChange={handleAmountChange}
                        placeholder="Enter dollar amount (1.00 - 999.99)"
                        className="w-full p-3 rounded-lg mb-2 border-2 border-yellow-500 focus:ring-2 focus:ring-orange-500"
                        min="1.00"
                        max="999.99"
                        step="0.01"
                    />
                    <p className="text-sm text-brown-600 mb-2 text-center">
                        {orderType === 'buy'
                            ? `Estimated sats to send: ${estimatedSats.toLocaleString()}`
                            : `Estimated sats to receive: ${estimatedSats.toLocaleString()}`
                        }
                    </p>
                    <button
                        onClick={handleTransaction}
                        className={`w-full p-3 rounded-lg text-white font-bold transition-colors duration-200 ${orderType === 'buy' ? 'bg-green-500 hover:bg-green-600' : 'bg-red-500 hover:bg-red-600'
                            }`}
                        disabled={!amount || parseFloat(amount) < 1.00 || parseFloat(amount) > 999.99}
                    >
                        {orderType === 'buy' ? 'Buy Dollar' : 'Sell Dollar'}
                    </button>
                </div>

                <div>
                    <h2 className="text-xl font-bold mb-4 text-brown-800">Recent Transactions</h2>
                    {renderTransactions()}
                </div>
            </div>
        </div>
    );
}

export default Dollar;
import React, { useState, useEffect, useCallback } from 'react';
import { useUser } from '../contexts/UserContext';
import axios from 'axios';
import { TonConnectButton, useTonWallet } from '@tonconnect/ui-react';

const API_BASE_URL = 'https://onering.simping.click/apedex/';

function Profile() {
  const { user, updateUser } = useUser();
  const wallet = useTonWallet();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [twitterState, setTwitterState] = useState(null);
  const [twitterConnected, setTwitterConnected] = useState(false);
  const [twitterUsername, setTwitterUsername] = useState(null);

  const updateProfile = useCallback(async (data) => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.post(`${API_BASE_URL}profile`, {
        ...data,
        token: user.token
      });
      updateUser(response.data);
    } catch (err) {
      console.error('Error updating profile:', err);
      setError('Failed to update profile. Please try again.');
    } finally {
      setLoading(false);
    }
  }, [user.token, updateUser]);

  useEffect(() => {
    let isInitialMount = true;
    if (wallet && !isInitialMount) {
      updateProfile({ ton_wallet: wallet.account.address });
    }
    return () => {
      isInitialMount = false;
    };
  }, [wallet, updateProfile]);

  useEffect(() => {
    const fetchTwitterState = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}twitter/login?token=${user.token}`);
        setTwitterState(response.data.state);
        setTwitterConnected(response.data.connected);
        if (response.data.connected) {
          setTwitterUsername(response.data.twitter_username);
        }
      } catch (err) {
        console.error('Error fetching Twitter state:', err);
        setError('Failed to fetch Twitter login state. Please try again.');
      }
    };

    fetchTwitterState();
  }, [user.token]);

  if (!user) return <div>Loading...</div>;

  return (
    <div className="p-4 bg-gradient-to-b from-yellow-100 to-orange-200 min-h-screen">
      <div className="max-w-2xl mx-auto">
        <div className="bg-gradient-to-r from-yellow-300 to-orange-400 p-8 rounded-xl shadow-lg mb-6">
          <div className="mb-4">
            <h1 className="text-2xl font-bold text-brown-800">@{user.telegram_username}</h1>
            <p className="text-sm text-brown-600">Level: {user.level}</p>
          </div>
        </div>

        <div className="bg-white p-6 rounded-xl shadow-md mb-6">
          <h2 className="text-xl font-bold text-brown-800 mb-4">TON Wallet</h2>
          <div className="flex flex-col items-center">
            <TonConnectButton
              className="bg-gradient-to-r from-yellow-400 to-orange-500 text-white font-bold py-2 px-4 rounded-full shadow-md hover:from-yellow-500 hover:to-orange-600 transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105"
            />
          </div>
        </div>

        <div className="bg-white p-6 rounded-xl shadow-md mb-6">
          <h2 className="text-xl font-bold text-brown-800 mb-4">Connect to X</h2>
          <div className="flex flex-col items-center">
            {twitterConnected ? (
              <div>
                <p className="text-lg text-brown-600 mb-2">X username:</p>
                <p className="text-xl font-semibold text-blue-600">@{twitterUsername}</p>
              </div>
            ) : (
              twitterState && (
                <a
                  href={`${API_BASE_URL}twitter/login/${twitterState}`}
                  className="bg-gradient-to-r from-blue-400 to-blue-500 text-white font-bold py-2 px-4 rounded-full shadow-md hover:from-blue-500 hover:to-blue-600 transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105"
                >
                  Connect to X
                </a>
              )
            )}
          </div>
        </div>

        {loading && <p className="text-center mt-4">Loading...</p>}
        {error && <p className="text-center mt-4 text-red-600">{error}</p>}
      </div>
    </div>
  );
}

export default Profile;